






































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle, BModal, VBModal, BSpinner
} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'
import {NotifyInfo, NotifySuccess} from "@/helpers/Toast";
import {VForm} from "@/global";

@Component({
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BModal,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
  }
})
export default class ClientSettings extends Vue {
  $refs!: {
    createForm: VForm
  }

  columns = [
    {
      label: 'Name',
      field: 'name',
    },
  ]

  rows: Record<string, MaintenanceAPI.ClientSettings.Item[]> = {};

  async created() {
    await this.getItems();
  }

  async getItems() {
    this.rows = await this.$api.maintenance.clientsettings.GetGroupedList();
  }

  async goTo(params) {
    await this.$router.push({
      name: "maintenance-clientsetting",
      params: {
        identifier: params.row.id
      }
    })
  }


  // Create Model
  createModel = {
    loading: false,
    name: null,
  }

  async create(bvModalEvt) {
    this.createModel.loading = true;
    bvModalEvt.preventDefault();

    if(!await this.$refs.createForm.validate()) {
      this.createModel.loading = false;
      return;
    }


    const res = await this.$api.maintenance.clientsettings.Create({name: this.createModel.name});
    if(res != null)
      await this.$router.push({
        name: "maintenance-clientsetting",
        params: {
          identifier: res.id,
          action: "edit"
        }
      })

    this.createModel.loading = false;
  }

  cancel() {
    this.$refs.createForm.reset();
    this.$set(this, "createModel", {name: null})
  }
}
